<template>
  <div id="terms" class="bodySpacer">
      <h2>Terms of Use:</h2>
    <section>
      <p>These Terms of Use (these &ldquo;<strong>Terms</strong>&rdquo;) are a legally binding contract between Share Save Spend&copy; LLC and/or its affiliates and/or its or their agents (collectively, &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;) regardingyour use of the Rising Gen Financial Literacy e-learning course and related platforms, materials, information, products, and content of ours (collectively, the &ldquo;<strong>Service</strong>&rdquo;).</p>
      
      <h3>PLEASE READ THESE TERMS CAREFULLY.</h3>

      <p>By clicking &ldquo;I ACCEPT&rdquo; or otherwise accessing or using the Service, you represent that you have the legal capacity to enter a contract and have read and understood, and as a condition to your use, agree to comply with and be bound by, these Terms. If you do not agree to these Terms, you are not authorized to access or use the Services.</p>

      <p><strong>License and Ownership.</strong> The Service is owned and operated by us. We own (or have been licensed): (a) all components of the Service, including, but not limited to, the content, information, data, computer code, products, software, services, visual interfaces, design, layout, look, appearance, and graphics; all intellectual property, logos, and marks used in connection with the Service; and any modifications, results, and derivate works of the Service; and (b) all of our proprietary methodologies, training modules, concepts, work approaches, platforms, know- how, techniques, discoveries, utilities, and inventions arising under or connected with the Service (&ldquo;<strong>Materials</strong>&rdquo;). You agree that the Materials are protected by intellectual property and other laws and that the use or misuse of the Materials, except as provided in these Terms, is strictly prohibited. Subject to your complete and ongoing compliance with these Terms, we grant you a nonexclusive, limited, non-transferable, non-sublicensable, revocable license to have online access to the Service solely for your individual personal and non-commercial use. Except as expressly granted in these Terms, we reserve all rights to the Service and Materials.</p>

      <p><strong>Restrictions.</strong> Except and solely to the extent such restriction is impermissible under applicable law, you shall not: (a) copy, modify, reproduce, reverse engineer, distribute, transmit, reuse, re- post, publicly perform, or otherwise display the Service, any portion of the Service, or the Materials, (b) sell or otherwise attempt to transfer access to the Service or any right to view, access, or use any Materials, or access or use the Service or any Materials for any commercial purpose, (c) access or use the Service or any Materials for any illegal purpose or in violation of any applicable law or right of a third party, (d) interfere with any security-related feature or the operation of the Service, (e) post upon or transmit through the Service anything that is false, libelous, defamatory, obscene, profane, infringes on any copyright or other rights of any other person or entity, or otherwise could result in civil or criminal liability, or (f) attempt to do any of the foregoing acts or assist or permit any third party in engaging in any of the foregoing acts. Any unauthorized use terminates the permission or license granted by us.</p>

      <p><strong>Login Credentials.</strong> If you choose or are provided with a username, password, or other piece of information as part of the security procedures for accessing and using the Service, you must treat such information as confidential, and you shall not disclose it to any third party. You agree that your account is personal to you and that you will not permit any third party to use your account, username, and/or password to access or use the Service or Materials. You agree that you are solely responsible for any and all activities that occur under your account, username and/or password.</p>

      <p><strong>Content of the Services.</strong> All Materials offered through the Service are for general informational purposes only and subject to change without notice. While we may endeavour to keep the Service up to date and correct, EVERYTHING ON THE SERVICES IS PROVIDED ON AN &ldquo;AS IS,&rdquo; &ldquo;AS AVAILABLE&rdquo; BASIS. We make no representations, warranties, guarantees, or promises of any kind, express or implied, regarding the Service and/or any Materials offered through the Service. We do not warrant that the Service, any portion of the Service, or any Materials offered through the Service will be uninterrupted, secure, or free of errors, viruses, or other harmful components, or that any such issues will be corrected.</p>

      <p><strong>DISCLAIMER; NO LIABILITY.</strong> WE DISCLAIM ANY AND ALL REPRESENTATIONS, WARRANTIES, GUARANTEES, OR PROMISES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES REGARDING MERCHANTABILITY, COMPLETENESS, ACCURACY, TIMELINESS, RELIABILITY, PERFORMANCE, SUITABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, AVAILABILITY, NON-INFRINGEMENT, OR ANYTHING ELSE REGARDING THE SERVICE OR REGARDING THE MATERIALS. THE MATERIALS ON THE SERVICE ARE NOT ACCOUNTING, FINANCIAL, INVESTMENT OR LEGAL ADVICE AND YOU SHOULD SEEK THAT TYPE OF ADVICE FROM PROFESSIONAL ADVISORS (I.E., YOUR ACCOUNTANT, INVESTMENT ADVISOR, FINANCIAL PLANNER AND ATTORNEY). ANY RELIANCE THAT YOU OR YOUR ORGANIZATION PLACE UPON ANYTHING CONTAINED OR RELATING TO THE SERVICE IS AT YOUR SOLE RISK.</p>

      <p>BY USING THE SERVICE, YOU AGREE THAT IN NO EVENT WILL WE BE LIABLE FOR ANY LOSS OR DAMAGE OF ANY NATURE, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL, OR ANY OTHER LOSS OR DAMAGE WHATSOEVER (WHETHER ARISING FROM OR RELATED TO ANY ACTION IN CONTRACT, NEGLIGENCE, TORT OR OTHERWISE) RELATING DIRECTLY OR INDIRECTLY TO YOUR USE OF THE SERVICE IN ANY WAY, EVEN IF WE ARE AWARE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES. YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SERVICE SHALL BE TO DISCONTINUE USING THE SERVICE.</p>

      <p>THE FOREGOING DISCLAIMERS AND LIMITATIONS OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

      <p><strong>Links.</strong> Through the Service you may be able to link to other sites. We have no control over and are not responsible for the nature, content, and availability of those sites. The inclusion of any links does not imply a recommendation or that we endorse anything on such linked sites. If you decide to access a third party’s site from a link in the Service, you do so at your sole risk and are subject to the terms of that site.</p>

      <p><strong>Equitable Relief.</strong> Unauthorized use of the Service may give rise to a claim for an injunction, damages, and/or may be a criminal offense.</p>

      <p><strong>Laws.</strong> You agree to comply with all applicable local, state, federal and international laws at all times with respect to your use of the Service. Your use of the Service and/or any of your accounts set up through the Service and these Terms of Use shall be governed by the laws of the State of Minnesota, without regard to the conflict of laws thereof, and the laws of the United States.</p>

      <p><strong>Indemnification.</strong> You agree to indemnify, defend, and hold harmless us and our directors, officers, employees, and agents from and against any and all liabilities, claims, losses, expenses, and damages, including, but not limited to, reasonable attorney fees, arising from or related to your use of the Service, your use of any account accessed through the Service, or the use by any other person accessing an account or the Service using your username and password. We can shut off access to your online account at any time without notice and without liability.</p>

      <p><strong>Modification.</strong> No oral modification or waiver of these Terms of Use or of any breach hereof will be effective. No delay or failure in exercising any right or remedy will constitute a waiver, nor will it preclude any exercise or further of that or any other right or remedy.</p>

    </section>
  </div>
</template>

<script>

export default {
  name: 'Terms',
  data () {
    return {

    }
  },
  computed: {
  },
  methods: {
  }
}

</script>
